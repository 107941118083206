
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import Select from '@/components/atoms/CustomSelect.vue'
import authHeader from '@/services/auth-header'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import BarriersCard from '@/components/molecules/card/BarriersCard.vue'
import Info from '@/assets/svg/info.svg?inline'
import vClickOutside from 'click-outside-vue3'
import { Vue3Lottie } from 'vue3-lottie'

export default defineComponent({
  name: 'CreateBuildingStepTwoSection',

  data () {
    const setBarrierSchema = yup.object().shape({})

    return {
      message: '',
      setBarrierSchema,
      isModalOpen: false,
      initProp: null,
      error: null,
      barrierTitle: '',
      selectDeviceType: 'message.dashboard.device_type',
      selectNameDevice: 'Name',
      deviceId: '',
      selectedDeviceType: '',
      selectedNameDevice: '',
      customNameBarier: '',
      barrierDescription: '',
      barrierName: [],
      imagePreview: null,
      isPreview: false,
      isPreviewVideo: false,
      errorBarrier: null,
      // building
      buildingId: null,
      image: null,
      errorBuilding: null,
      isPrompt: false,
      isPreviewBuilding: false,
      imgId: null,
      barrierTypeSelected: 'smart',
      // new
      dataDeviceType: {},
      dataNameDevice: [],
      deviceTypes: '',
      barriers: {},
      isLoading: true,
      openPopupDeleteBarrier: false,
      barrierId: null,
      updateBarrier: false,
      barrierUpdateTypeSelected: true,
      buildingData: {}
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    AlertBlock,
    DefaultPopup,
    Select,
    BarriersCard,
    Info,
    LottieAnimation: Vue3Lottie
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  watch: {
    // $route (to, from) {
    //   this.handleDiscard()
    // },
    barrierTypeSelected () {
      if (this.barrierUpdateTypeSelected) {
        this.barrierTitle = ''
        this.selectDeviceType = 'message.dashboard.device_type'
        this.selectedDeviceType = ''
        this.barrierDescription = ''
        this.file = null
        this.imagePreview = require('@/assets/image/barrier.png')
        this.deviceId = ''
        this.errorBarrier = null
        this.dataNameDevice = ''
      }
    }
  },
  mounted () {
    const lang = JSON.parse(localStorage.getItem('lang'))
    this.activeLang = lang
    this.buildingId = this.$route.query.buildingId
    this.getBarriers()
    this.getDeviceTypes()
    this.getBuilding()
  },
  methods: {
    getBuilding () {
      this.$store.dispatch('property/getBuilding', this.buildingId).then(
        (res) => {
          this.buildingData = res.data
        },
        (error) => {
          this.error = error
        }
      )
    },
    saveBuilding () {
      this.$store.dispatch('property/saveBuildingSecondStep', this.buildingId).then(
        (res) => {
          this.$router.push('/dashboard/property')
        },
        (error) => {
          this.errorBuilding = error.response.data.errors
        }
      )
    },
    handleDiscard () {
      this.$store.dispatch('property/discardBuilding', this.buildingId).then(
        () => {
          this.$router.push('/dashboard/property')
        },
        (error) => {
          this.errorBuilding = error.response.data.errors
        }
      )
    },
    openDeleteBarrier (id) {
      this.openPopupDeleteBarrier = true
      this.barrierId = id
    },
    handleCloseModalDeleteBarrier () {
      this.openPopupDeleteBarrier = false
    },
    getBarriers (data) {
      data = this.buildingId
      this.$store.dispatch('barriers/getBuildingBarriers', data).then(
        (res) => {
          this.barriers = res
          this.isLoading = false
        },
        (error) => {
          this.error = error.response.data.errors
          this.isLoading = false
        }
      )
    },
    getDeviceTypes () {
      this.$store.dispatch('barriers/getDeviceTypes').then(
        (res) => {
          this.dataDeviceType = res
        },
        (error) => {
          this.error = error.response.data.message
        }
      )
    },
    getDevicesNames (query) {
      query = '?filter[manufacture]=' + this.deviceTypes.value + '&filter[usage]=false'
      this.$store.dispatch('devices/getDevices', query).then(
        (res) => {
          this.dataNameDevice = res.data
        },
        (error) => {
          this.errorDevices = error.response
        }
      )
    },
    goFirstStepCreateBuilding () {
      this.$router.push(`/dashboard/create-building?buildingId=${this.buildingId}`)
    },
    handleOpenModal (id) {
      this.deleteListingId = id
      this.barrierId = id
      this.isModalOpen = true
      this.barrierUpdateTypeSelected = true
      this.getBuildingBarrier()
    },
    handleCloseModal () {
      this.isModalOpen = false
      this.barrierTitle = ''
      this.barrierDescription = ''
      this.deviceTypes = ''
      this.errorBarrier = null
      this.file = null
      this.imagePreview = require('@/assets/image/barrier.png')
      this.isPreviewVideo = require('@/assets/image/barrier.png')
    },
    createBarrier (data) {
      this.isLoading = true
      const API_URL = process.env.VUE_APP_URL
      const formData = new FormData()
      if (this.file) {
        formData.append('attachment', this.file)
      }
      formData.append('title', data.barrier_title)
      formData.append('type', this.barrierTypeSelected)
      formData.append('description', this.barrierDescription)
      if (this.deviceId) {
        formData.append('device_id', this.deviceId)
      }
      if (this.updateBarrier === false) {
        this.axios.post(API_URL + 'provider/buildings/' + this.buildingId + '/barriers', formData, { headers: authHeader() }).then(() => {
          this.isModalOpen = false
          this.barrierTitle = ''
          this.barrierDescription = ''
          this.selectedDeviceType = ''
          this.deviceId = ''
          this.isPreview = false
          this.isPreviewVideo = false
          this.getBarriers()
          this.errorBarrier = null
          this.isLoading = false
          this.file = null
          this.imagePreview = require('@/assets/image/barrier.png')
          this.updateBarrier = false
        },
        (error) => {
          this.errorBarrier = error.response.data.errors
          this.isLoading = false
        }
        )
      } else {
        this.axios.post(API_URL + 'provider/buildings/' + this.buildingId + '/barriers/' + this.barrierId + '/update', formData, { headers: authHeader() }).then(() => {
          this.isModalOpen = false
          this.barrierTitle = ''
          this.barrierDescription = ''
          this.selectedDeviceType = ''
          this.isPreview = false
          this.isPreviewVideo = false
          this.getBarriers()
          this.errorBarrier = null
          this.isLoading = false
          this.file = null
          this.deviceId = ''
          this.imagePreview = require('@/assets/image/barrier.png')
          this.updateBarrier = false
        },
        (error) => {
          this.errorBarrier = error.response.data.errors
          this.isLoading = false
        }
        )
      }
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('thumbnail', this.file)
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        function () {
          this.imagePreview = reader.result
          this.isPreview = true
          this.isPreviewVideo = false
        }.bind(this),
        false
      )
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file)
        }
        console.log(this.file.type, 'this.file.type')
        if (this.file.type === 'video/mp4' || this.file.type === 'video/quicktime') {
          this.isPreview = false
          this.isPreviewVideo = true
        }
      }
    },
    deleteBarrier () {
      this.isLoading = true
      const ids = {
        buildingId: this.buildingId,
        barrierId: this.barrierId
      }
      this.$store.dispatch('barriers/deleteBuildingBarrier', ids).then(
        () => {
          this.getBarriers()
          this.openPopupDeleteBarrier = false
        },
        (error) => {
          this.errorDevices = error.response
          this.isLoading = false
        }
      )
    },
    handleOptionDeviceTypeSelected (selectedOption) {
      this.deviceTypes = selectedOption
      this.deviceId = null
      if (this.errorBarrier) {
        this.errorBarrier.device_id = null
      }
      this.getDevicesNames()
    },
    handleOptionNameDeviceSelected (selectedOption) {
      if (this.errorBarrier) {
        this.errorBarrier.device_id = null
      }
      this.deviceId = selectedOption.id
    },
    deletePhoto () {
      this.imagePreview = require('@/assets/image/barrier.png')
      this.isPreviewVideo = require('@/assets/image/barrier.png')
      this.isPreviewVideo = false
      this.file = null
    },
    createUnit () {
      this.$store.dispatch('property/saveBuildingSecondStep', this.buildingId).then(
        () => {
          this.$router.push(`/dashboard/create-unit-step-one?buildingId=${this.buildingId}`)
        },
        (error) => {
          this.errorBuilding = error.response.data.errors
        }
      )
    },
    getBuildingBarrier (data) {
      data = {
        buildingId: this.buildingId,
        barrierId: this.barrierId
      }
      this.$store.dispatch('barriers/getBuildingBarrier', data).then(
        (res) => {
          this.barrierUpdateTypeSelected = false
          this.barrierTypeSelected = res.type.value
          this.barrierTitle = res.title
          if (this.barrierTitle) {
            this.updateBarrier = true
          }
          this.selectDeviceType = res.device?.manufacture.title
          this.deviceTypes = res.device?.manufacture.value
          this.barrierDescription = res.description
          if (res.attachment) {
            this.imagePreview = res.attachment.url
            this.isPreview = true
            this.isPreviewVideo = false
          }
          if (res.device) {
            this.deviceId = res.device.id
            this.dataNameDevice = res.device.name
            this.selectNameDevice = res.device.name
          }
        },
        (error) => {
          this.errorBuilding = error.response.data.errors
        }
      )
    },
    deleteBuilding () {
      this.$store.dispatch('property/deleteBuilding', this.buildingId).then(
        () => {
          this.$router.push('/dashboard/property')
        },
        (error) => {
          this.errorDeleteBuilding = error.response.data.errors
        }
      )
    }
  }
})
